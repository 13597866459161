import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const Banner = () => {
  const data = useStaticQuery(graphql`
    {
      allMasonSectionContentCreative(
        filter: { meta: { name: { eq: "main_banner" } } }
        sort: {fields: created_time, order: DESC}
        limit: 1
      ) {
        nodes {
          url
          meta {
            name
            url
          }
        }
      }
    }
  `)
  console.log(data);
  return (
    <div className="banner-wrap full-bleed bg-gray-200 overflow-hidden">
      {data.allMasonSectionContentCreative.nodes.map(banner => (
        <a target="_blank" href={banner.meta.url===null?"/":banner.meta.url}>
          <div className="img-wrap w-full h-full">
            <img
              className="w-full h-full object-cover"
              src={banner.url}
              alt="banner image"
            />
          </div>
        </a>
      ))}
    </div>
  )
}

export default Banner
