import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const GridC = () => {
  const data = useStaticQuery(graphql`
    {
      small: allMasonSectionContentCreative(
        filter: { meta: { name: { eq: "sec3_small" } } }
        sort: { fields: created_time, order: DESC }
      ) {
        nodes {
          url
          meta {
            name
            url
            order
          }
        }
        distinct(field: meta___order)
      }
      medium: allMasonSectionContentCreative(
        filter: { meta: { name: { eq: "sec3_med" } } }
        sort: { fields: created_time, order: DESC }
      ) {
        nodes {
          url
          meta {
            name
            url
            order
          }
        }
        distinct(field: meta___order)
      }
    }
  `)
  return (
    <div className="grid-layout-wrap grid-c-wrap grid grid-cols-4 grid-rows-2 gap-4 mt-4">
      {data.small.distinct.map(position => {
        let card = data.small.nodes.filter(
          elem => elem.meta.order === position
        )[0]
        return (
          <div className="grid-img-wrap small-grid-card">
            <a
              target="_blank"
              href={card.meta.url !== null ? card.meta.url : "/"}
            >
              <img
                className="w-full h-full object-cover"
                src={card.url}
                alt="grid image"
              />
            </a>
          </div>
        )
      })}
      {data.medium.distinct.map(position => {
        let card = data.medium.nodes.filter((elem)=>elem.meta.order===position)[0]
        return (
          <div className="grid-img-wrap medium-grid-card">
            <a
              target="_blank"
              href={card.meta.url !== null ? card.meta.url : "/"}
            >
              <img
                className="w-full h-full object-cover"
                src={card.url}
                alt="grid image"
              />
            </a>
          </div>
        )
      })}
    </div>
  )
}

export default GridC
