import React from "react"
import Slideshow from "../SlideShow/Slideshow"
import { useStaticQuery, graphql, Link } from "gatsby"

const GridA = () => {
  const data = useStaticQuery(graphql`
    {
      slides: allMasonSectionContentCreative(
        filter: { meta: { name: { eq: "slide" } } }
        sort: { fields: created_time, order: DESC }
      ) {
        nodes {
          url
          meta {
            name
            url
            order
          }
        }
        distinct(field: meta___order)
      }
      products: allMasonSectionContentCreative(
        filter: { meta: { name: { eq: "featured_shoes" } } }
        sort: { fields: created_time, order: DESC }
      ) {
        nodes {
          url
          meta {
            name
            url
            order
          }
        }
        distinct(field: meta___order)
      }
    }
  `)
  // const products = useStaticQuery(graphql`
  //   {

  //   }
  // `)
  return (
    <div className="grid-layout-wrap grid-a-wrap grid grid-cols-4 grid-rows-2 gap-4 mt-4">
      {data.products.distinct.map(position => {
        let card = data.products.nodes.filter((elem)=>elem.meta.order===position)[0]
        return (
          <div className="grid-img-wrap small-grid-card">
            <a
              target="_blank"
              href={card.meta.url !== null ? card.meta.url : "/"}
            >
              <img
                className="w-full h-full object-cover"
                src={card.url}
                alt="product image"
              />
            </a>
          </div>
        )
      })}
      <div className="relative grid-img-wrap big-grid-card">
        <Slideshow data={data.slides} />
      </div>
    </div>
  )
}

export default GridA
