import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const GridB = () => {
  const data = useStaticQuery(graphql`
    {
      allMasonSectionContentCreative(
        filter: { meta: { name: { eq: "featured_product" } } }
        sort: { fields: created_time, order: DESC }
      ) {
        nodes {
          url
          meta {
            name
            url
            order
          }
        }
        distinct(field: meta___order)
      }
    }
  `)
  return (
    <div className="grid-layout-wrap grid-b-wrap grid grid-cols-4 grid-rows-2 gap-4 mt-4">
      {data.allMasonSectionContentCreative.distinct.map(position => {
        let card = data.allMasonSectionContentCreative.nodes.filter((elem)=>elem.meta.order===position)[0]
        console.log(card);
        return (
          <div
            className={`grid-img-wrap ${
              position !== "0" ? "small-grid-card" : "big-grid-card"
            }`}
          >
            <a
              target="_blank"
              href={card.meta.url !== null ? card.meta.url : "/"}
            >
              <img
                className="w-full h-full object-cover"
                src={card.url}
                alt="banner image"
              />
            </a>
          </div>
        )
      })}
    </div>
  )
}

export default GridB
