import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { ChevronLeft, ChevronRight } from "react-feather"
import "./Slideshow.scss"

const Slideshow = ({ data }) => {
  const [index, setIndex] = useState(0)
  useEffect(() => {
    const autoplay = setTimeout(() => {
      index === data.nodes.length - 1 ? setIndex(0) : setIndex(index + 1)
    }, 2000)
    return () => clearTimeout(autoplay)
  }, [index])
  return (
    <div className="slider-wrap w-full h-full relative">
      <div className="controls">
        <div
          className="btn shadow-md prev"
          onClick={() => {
            index === 0 ? setIndex(data.nodes.length - 1) : setIndex(index - 1)
          }}
        >
          <ChevronLeft />
        </div>
        <div
          className="btn shadow-md next"
          onClick={() => {
            index === data.nodes.length - 1 ? setIndex(0) : setIndex(index + 1)
          }}
        >
          <ChevronRight />
        </div>
      </div>
      {data.distinct.map(position => {
        let card = data.nodes.filter((elem)=>elem.meta.order===position)[0]
        return (<a
          className={`slider-card-wrap block ${
            index === parseInt(position) ? "slider-card-active" : "hidden"
          }`}
          target="_blank" href={card.url ? card.url : "/"}
        >
            <img
              className="w-full h-full object-cover"
              src={card.url}
              alt="slide image"
            />
        </a>)
})}
    </div>
  )
}

export default Slideshow
