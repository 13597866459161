import * as React from "react"
import Banner from "../components/Banner/Banner"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
// import Seo from "../components/seo"

import GridA from "../components/GridA/GridA"
import GridB from "../components/GridB/GridB"
import GridC from "../components/GridC/GridC"
import SmallBanner from "../components/SmallBanner/SmallBanner"
import GridE from "../components/GridE/GridE"
import Seo from "../components/seo"

const IndexPage = ({ data }) => (
  <Layout>
    <Seo title={"Garnet | Shoes"}/>
    <Banner />
    <GridA />
    <GridB />
    <GridC />
    <SmallBanner />
    <GridE />
    {/* {data.allMasonQuoteCreative.nodes.map(banner => (
      <img src={banner.url} alt="banner" />
    ))} */}
  </Layout>
)

// export const query = graphql`
//   {
//     allMasonQuoteCreative {
//       nodes {
//         url
//       }
//     }
//   }
// `

export default IndexPage
